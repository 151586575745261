<template>
	<div>
		<img loading="lazy" alt="" :src="'https://' + news.photo[0].path" class="w-full border h-60 object-cover border-secondry" />
		<div class="flex items-center text-dateText my-2">
			<span>{{ $i18n.locale == 'ar' ? news.category_ar : news.category_en }} - </span>
			<span class="mx-1">
				{{ $moment(news.created_at).format('YYYY-MM-DD') }}
			</span>
		</div>
		<h1 class="text-newsText font-medium text-lg tracking-wide my-1 line-clamp-title">
			{{ $i18n.locale == 'ar' ? news.title_ar : news.title_en }}
		</h1>
		<router-link
			:to="{ name: 'newsDetails', params: { id: news.id } }"
			class="text-btn flex items-center font-medium tracking-wide my-1"
		>
			<span class="hover:font-bold"> {{ $t('Home.about.read_more') }}</span>
			<img
				:class="$i18n.locale == 'EN' ? 'transform rotate-180' : ''"
				class="mx-2 object-cover"
				src="../../assets/VectorBlue.svg"
				alt=""
				loading="lazy"
			/>
		</router-link>
	</div>
</template>
<script>
import lineClamp from 'line-clamp';

export default {
	name: 'newsComponent',
	props: ['news'],
	data() {
		return {
			URL: process.env.VUE_APP_URL,
		};
	},
	mounted() {
		const title = document.querySelector('.line-clamp-title');
		lineClamp(title, 2);
	},
};
</script>
<style scoped>
.line-clamp-title {
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 2;
	text-overflow: ellipsis;
	overflow: hidden;
}
</style>
