<template>
	<div class="w-full bg-white py-12">
		<div class="lg:hidden container mx-auto px-5">
			<h1>
				<span class="text-2xl text-Text w-full">
					{{ $t('Home.news.h1') }}
				</span>
				<span class="block w-16 bg-info my-1 rounded-lg h-1"></span>
			</h1>
		</div>
		<div
			class="container mx-auto px-5 lg:px-8 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-10 py-12 gap-12 lg:gap-16"
		>
			<div class="hidden lg:flex items-center justify-center flex-wrap lg:col-span-1">
				<div>
					<h3 class="text-2xl text-Text w-full">{{ $t('Home.news.h1') }}</h3>
					<span class="block w-16 bg-info my-1 rounded-lg h-1 mx-auto"></span>
					<router-link
						:to="{ name: 'News' }"
						class="text-btn text-center mt-5 flex items-center justify-center "
					>
						<span class="mx-1 hover:font-bold">{{ $t('Home.news.seeMore') }}</span>
						<img
							:class="$i18n.locale == 'EN' ? 'transform rotate-180' : ''"
							class="text-btn object-cover"
							src="../../assets/VectorBlue.svg"
							alt=""
							loading="lazy"
						/>
					</router-link>
				</div>
			</div>
			<NewsComponent
				v-for="(item, index) in lastNews"
				:key="index"
				:news="item"
				class="col-auto  lg:col-span-3"
				:data-aos="$i18n.locale == 'EN' ? 'fade-left' : 'fade-right'"
				data-aos-duration="600"
				data-aos-easing="ease-in-out"
				:data-aos-delay="index == 1 ? '200' : index == 2 ? '350' : '0'"
			/>
		</div>
		<div class="lg:hidden container mx-auto px-5">
			<router-link
				:to="{ name: 'News' }"
				class="text-btn border py-2 px-5 w-1/3 border-btn mt-5 flex items-center justify-center focus:outline-none"
				:class="$i18n.locale == 'ar' ? 'flex-row-reverse' : null"
			>
				<img class="text-btn object-cover" src="../../assets/VectorBlue.svg" alt="" loading="lazy" />
				<span class="mx-2">{{ $t('Home.news.seeMore') }}</span>
			</router-link>
		</div>
	</div>
</template>
<script>
import NewsComponent from '../utils/NewsComponent.vue';
export default {
	name: 'lastNews',
	data() {
		return {
			URL: process.env.VUE_APP_URL,
		};
	},
	components: {
		NewsComponent,
	},
	computed: {
		lastNews() {
			return this.$store.getters.lastNews;
		},
	},
};
</script>
<style scoped>
svg {
	fill: currentColor;
}
</style>
