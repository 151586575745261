import { render, staticRenderFns } from "./Header.vue?vue&type=template&id=25e85700&scoped=true&"
var script = {}
import style0 from "./Header.vue?vue&type=style&index=0&id=25e85700&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "25e85700",
  null
  
)

export default component.exports