<template>
	<div class="bg-primary py-12">
		<div class="container mx-auto px-5 lg:px-8 py-12">
			<h1 class="text-white text-3xl text-center">
				<span> {{ $t('Home.services.h1') }}</span>
				<span class="bg-info w-12 h-1 rounded-lg block mx-auto"></span>
			</h1>
			<div class="mt-12 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-12 lg:gap-16 mx-auto">
				<Service v-for="(item, index) in data" :key="index" :item="item" @fillDialog="fillDialog" />
			</div>
		</div>
		<div
			v-if="toggle"
			class="fixed inset-0 flex items-center justify-center z-40"
			style="background: rgba(0, 0, 0, 0.3)"
		>
			<renewReqForm :dialog="dialog" @toggle="fillDialog" />
		</div>
	</div>
</template>
<script>
import doctor from '../../assets/Group.svg';
import companyImg from '../../assets/company.svg';
import Frame from '../../assets/Frame.svg';
import Service from '../utils/Service';
import renewReqForm from '../layout/renewReqForm';

export default {
	name: 'Services',
	data() {
		return {
			dialog: null,
			toggle: false,
		};
	},
	computed: {
		data() {
			return [
				{
					img: doctor,
					id: 1,
					title: this.$t('Home.services.doctor.title'),
					content: this.$t('Home.services.doctor.content'),
					link: 'indivualGeneral',
				},
				{
					img: companyImg,
					id: 2,
					title: this.$t('Home.services.company.title'),
					content: this.$t('Home.services.company.content'),
					link: 'companyGeneral',
				},
				{
					img: Frame,
					id: 3,
					title: this.$t('Home.services.lab.title'),
					content: this.$t('Home.services.lab.content'),
					link: 'Labs',
				},
			];
		},
	},
	methods: {
		fillDialog(data) {
			if (data) {
				this.toggle = true;
				this.dialog = data;
			} else {
				this.toggle = false;
				this.dialog = null;
			}
		},
	},
	components: {
		Service,
		renewReqForm,
	},
};
</script>
