<template>
  <div id="home" class="bg-white">
    <Header />
    <News />
    <Services />
    <About />
    <Maps />
  </div>
</template>
<script>
import Header from "../components/Home/Header";
import News from "../components/Home/News";
import Services from "../components/Home/Services";
import About from "../components/Home/About";
import Maps from "../components/Home/Maps";
export default {
  name: "Home",
  components: {
    Header,
    News,
    Services,
    About,
    Maps,
  },
};
</script>
